import { Route, Routes, Navigate } from "react-router-dom";
import { UserState } from "adminPanel/user/userSlice";
import { useAppSelector } from "app/hooks";
import { ReactNode, Suspense } from "react";
import React from "react";
import isNonEmptyGuid from "helpers/isNonEmptyGuid";

import UserLoginPage from "adminPanel/user/UserLoginPage";
import ErrorPage from "components/pages/ErrorPage";
import CenteredCircularProgress from "components/general/CenteredCircularProgress";
import { EmployeeSettingSlice } from "amnPos/slices/EmployeeSettingSlice";
import { IClientPagePermissions } from "models/IAmnPageControllersPermissionsBase";
import { POSType } from "amnPos/pages/UserHomePage";
import { RouterNavigateType } from "models/RouterNavigateType";
const StatisticsPage = React.lazy(
  () => import("amnShared/clinetAction/statistics/StatisticsPage")
);
const DeliveryOrdersPage = React.lazy(
  () => import("amnShared/clinetAction/delivery/DeliveryOrdersPage")
);
const PickupOrdersPage = React.lazy(
  () => import("amnShared/clinetAction/pickup/PickupOrdersPage")
);

const MenusPage = React.lazy(() => import("adminPanel/menus/MenusPage"));

const EditMenusPage = React.lazy(
  () => import("adminPanel/menus/EditMenusPage")
);

const PreparedMaterialsPage = React.lazy(
  () => import("adminPanel/preparedMaterials/PreparedMaterialsPage")
);

const CombosPage = React.lazy(() => import("adminPanel/combos/ComboPage"));

const EditCombosPage = React.lazy(
  () => import("adminPanel/combos/EditCombosPage")
);

const EditPreparedMaterialsPage = React.lazy(
  () => import("adminPanel/preparedMaterials/EditPreparedMaterialsPage")
);
const GeneralModifierPage = React.lazy(
  () => import("adminPanel/generalModifier/GeneralModifierPage")
);
const CreateGeneralModifierPage = React.lazy(
  () => import("adminPanel/generalModifier/EditGeneralModifierPage")
);
const EditGeneralModifierPage = React.lazy(
  () => import("adminPanel/generalModifier/EditGeneralModifierPage")
);

const OrganizationSignUpPage = React.lazy(
  () => import("adminPanel/organization/OrganizationSignUpPage")
);
const EmailValidationPage = React.lazy(
  () => import("adminPanel/user/EmailValidationPage")
);
const VerifyYourEmailPage = React.lazy(
  () => import("adminPanel/organization/VerifyYourEmailPage")
);
const PosLoginPage = React.lazy(() => import("amnPos/pages/PosLoginPage"));
const UserProfile = React.lazy(() => import("amnShared/user/UserProfile"));

const UsersList = React.lazy(() => import("adminPanel/user/UsersList"));
const UserPage = React.lazy(() => import("adminPanel/user/UserPage"));

const FinishedTransaction = React.lazy(
  () => import("amnPos/action/finishedTransactions/FinishedTransaction")
);

const RestMaterialsTransactions = React.lazy(
  () => import("amnRestaurants/materialsTransaction/RestMaterialsTransactions")
);

const TransactionDetails = React.lazy(
  () =>
    import(
      "amnPos/action/finishedTransactions/TransactionDetails/TransactionDetails"
    )
);

const ShiftSummaryPOSPage = React.lazy(
  () => import("amnPos/FrontEndReports/ShiftSummary/ShiftSummaryPage")
);

const ShiftSummaryRestPage = React.lazy(
  () => import("amnRestaurants/FrontEndReports/ShiftSummary/ShiftSummaryPage")
);

const ShiftSummaryBackOfficePage = React.lazy(
  () =>
    import("adminPanel/reports/FrontEndReports/ShiftSummary/ShiftSummaryPage")
);

const CalculatedCashDetailsPOSPage = React.lazy(
  () =>
    import(
      "amnPos/FrontEndReports/CalculatedCashDetails/CalculatedCashDetailsPage"
    )
);

const CalculatedCashDetailsRestPage = React.lazy(
  () =>
    import(
      "amnRestaurants/FrontEndReports/CalculatedCashDetails/CalculatedCashDetailsPage"
    )
);

const CalculatedCashDetailsBackOfficePage = React.lazy(
  () =>
    import(
      "adminPanel/reports/FrontEndReports/CalculatedCashDetails/CalculatedCashDetailsPage"
    )
);

const MaterialsTransactionDetailsPOSPage = React.lazy(
  () =>
    import(
      "amnPos/FrontEndReports/MaterialsTransactionDetails/MaterialsTransactionDetailsPage"
    )
);

const MaterialsTransactionDetailsRestPage = React.lazy(
  () =>
    import(
      "amnRestaurants/FrontEndReports/MaterialsTransactionDetails/MaterialsTransactionDetailsPage"
    )
);

const MaterialsTransactionDetailsBackOfficePage = React.lazy(
  () =>
    import(
      "adminPanel/reports/FrontEndReports/MaterialsTransactionDetails/MaterialsTransactionDetailsPage"
    )
);

const ExternalTransactionDetailsPOSPage = React.lazy(
  () =>
    import(
      "amnPos/FrontEndReports/ExternalTransactionDetails/ExternalTransactionDetailsPage"
    )
);

const ExternalTransactionDetailsRestPage = React.lazy(
  () =>
    import(
      "amnRestaurants/FrontEndReports/ExternalTransactionDetails/ExternalTransactionDetailsPage"
    )
);

const ExternalTransactionDetailsBackOfficePage = React.lazy(
  () =>
    import(
      "adminPanel/reports/FrontEndReports/ExternalTransactionDetails/ExternalTransactionDetailsPage"
    )
);

const ShiftClosingDetailsPOSPage = React.lazy(
  () =>
    import("amnPos/FrontEndReports/ShiftClosingDetails/ShiftClosingDetailsPage")
);

const ShiftClosingDetailsRestPage = React.lazy(
  () =>
    import(
      "amnRestaurants/FrontEndReports/ShiftClosingDetails/ShiftClosingDetailsPage"
    )
);

const ShiftClosingDetailsBackOfficePage = React.lazy(
  () =>
    import(
      "adminPanel/reports/FrontEndReports/ShiftClosingDetails/ShiftClosingDetailsPage"
    )
);

const MainPage = React.lazy(() => import("components/pages/MainPage"));
const UserHomePage = React.lazy(() => import("amnPos/pages/UserHomePage"));

const PosMainPage = React.lazy(() => import("amnPos/pages/PosMainPage"));

const RestaurantsMainPage = React.lazy(
  () => import("amnRestaurants/RestaurantsMainPage")
);

const PosListPage = React.lazy(() => import("adminPanel/pos/PosListPage"));
const EditPosPage = React.lazy(() => import("adminPanel/pos/EditPosPage"));
const CategoryPage = React.lazy(
  () => import("adminPanel/category/CategoryPage")
);
const TagListPage = React.lazy(() => import("adminPanel/tags/TagListPage"));
const ItemsPage = React.lazy(() => import("adminPanel/items/ItemsPage"));
const SalesReports = React.lazy(
  () => import("adminPanel/reports/SalesReports")
);
const CustomerStatementReport = React.lazy(
  () => import("adminPanel/reports/CustomerStatementReport")
);
const CustomerStatementRestaurantReport = React.lazy(
  () =>
    import("amnRestaurants/FrontEndReports/CustomerStatementRestaurantReport")
);
const CustomerStatementPosReport = React.lazy(
  () => import("amnPos/FrontEndReports/CustomerStatementPosReport")
);
const CustomersBalanceStatementReport = React.lazy(
  () => import("adminPanel/reports/CustomersBalanceStatementReport")
);
const ShiftReports = React.lazy(
  () => import("adminPanel/reports/ShiftReports")
);
const EditItemPage = React.lazy(() => import("adminPanel/items/EditItemPage"));
const EditClientPage = React.lazy(
  () => import("amnShared/clients/EditClientPage")
);
const ClientsListPage = React.lazy(
  () => import("adminPanel/clients/ClientsListPage")
);
const SalesmenPage = React.lazy(
  () => import("adminPanel/salesman/SalesmenPage")
);
const EditISalesmanPage = React.lazy(
  () => import("adminPanel/salesman/EditISalesmanPage")
);
const StoresListPage = React.lazy(
  () => import("adminPanel/stores/StoresListPage")
);
const EditStorePage = React.lazy(
  () => import("adminPanel/stores/EditStorePage")
);
const EmployeesPage = React.lazy(
  () => import("adminPanel/employee/EmployeesPage")
);
const EditEmployeePage = React.lazy(
  () => import("adminPanel/employee/EditEmployeePage")
);
const DriverPage = React.lazy(() => import("adminPanel/driver/DriverPage"));
const EditPrintDesignPage = React.lazy(
  () => import("adminPanel/Print/EditPrintDesignPage")
);

const MaterialsTransactions = React.lazy(
  () => import("amnPos/materialsTransaction/MaterialsTransactions")
);

const ShiftsAndExternalsPage = React.lazy(
  () => import("amnPos/shiftsAndExternals/ShiftsAndExternalsPage")
);

const OrderManagementPage = React.lazy(
  () => import("amnShared/orderManagement/OrderManagementPage")
);

const TicketSection = React.lazy(
  () => import("amnPos/materialsTransaction/ticketArea/TicketSection")
);

const ConfigurationPage = React.lazy(
  () => import("amnPos/configuration/ConfigurationPage")
);

const PosClientsListPage = React.lazy(
  () => import("amnPos/action/client/PosClientsListPage")
);
const TicketPayment = React.lazy(
  () => import("amnPos/ticket/payment/TicketPayment")
);

const PrintingDesignListPage = React.lazy(
  () => import("adminPanel/Print/PrintingDesignListPage")
);
const SettingsPage = React.lazy(
  () => import("adminPanel/settings/SettingsPage")
);
const DriversPage = React.lazy(() => import("adminPanel/driver/DriversPage"));

const NoPermissionsPage = React.lazy(
  () => import("components/pages/NoPermissionsPage")
);

const DashboardPage = React.lazy(
  () => import("adminPanel/dashboard/DashboardPage")
);

export default function Router() {
  const user = useAppSelector((state) => state.user as UserState);
  const isLoggedIn = isNonEmptyGuid(user.id) && !user.isCasher;
  const isPosLoggedIn = isNonEmptyGuid(user.id) && user.isCasher;
  const posId = localStorage.getItem("posId");
  const posType = localStorage.getItem("posType");
  const posUser = useAppSelector(
    (state) => state.employeeSettings as EmployeeSettingSlice
  );

  function getClientPagePermissions() {
    if (isLoggedIn) return undefined;

    return {
      canAdd: posUser.clientOptions.canAddNewClient,
      canModify: posUser.clientOptions.canModifyExistingClient,
      addressPageControllersPermissions: {
        canAdd: posUser.clientOptions.canAddNewAddress,
        canModify: posUser.clientOptions.canModifyAddress,
        canChangeDefaultCountryAndProvince:
          posUser.clientOptions.canChangeDefaultCountryAndProvince,
      },
    } as IClientPagePermissions;
  }
  function getPath(pathName: string) {
    return `/${
      user.organizationId ? user.organizationId : ":organizationId"
    }${pathName}`;
  }

  function WrapSuspense(component: ReactNode) {
    return (
      <Suspense fallback={<CenteredCircularProgress />}>{component}</Suspense>
    );
  }

  function LoggedInPosHome(component: ReactNode) {
    let targetComponent = component;

    if (!isPosLoggedIn) {
      targetComponent = <Navigate to={getPath("/login")} />;
    }

    if (isNonEmptyGuid(user.id)) {
      if (!user.isCasher) {
        targetComponent = <Navigate to={getPath("/permissions")} />;
      }
    }

    return WrapSuspense(targetComponent);
  }

  function LoggedInPos(component: ReactNode) {
    let targetComponent = component;
    if (isNonEmptyGuid(user.id) || isNonEmptyGuid(user.posUserId)) {
      if (!user.isCasher) {
        targetComponent = <Navigate to={getPath("/permissions")} />;
      }
      if (
        posType !== undefined &&
        posType !== null &&
        posType != POSType.POS.toString()
      ) {
        targetComponent = <Navigate to={getPath("/restaurants")} />;
      }
    } else {
      targetComponent = <Navigate to={getPath("/login")} />;
    }
    return WrapSuspense(targetComponent);
  }

  function LoggedInRest(component: ReactNode) {
    let targetComponent = component;
    if (isNonEmptyGuid(user.id) || isNonEmptyGuid(user.posUserId)) {
      if (!user.isCasher) {
        targetComponent = <Navigate to={getPath("/permissions")} />;
      }
      if (
        posType !== undefined &&
        posType !== null &&
        posType != POSType.Restaurants.toString()
      ) {
        targetComponent = <Navigate to={getPath("/pos")} />;
      }
    } else {
      targetComponent = <Navigate to={getPath("/login")} />;
    }

    return WrapSuspense(targetComponent);
  }

  function LoggedIn(component: ReactNode) {
    let targetComponent = component;

    if (!isLoggedIn) {
      targetComponent = <Navigate to={getPath("/login")} />;
    }

    if (isNonEmptyGuid(user.id)) {
      if (user.isCasher) {
        targetComponent = <Navigate to={getPath("/permissions")} />;
      }
    }

    return WrapSuspense(targetComponent);
  }

  function AllowGetPage(component: ReactNode) {
    let targetComponent = component;
    if (isLoggedIn) targetComponent = <Navigate to={getPath("/")} />;
    else if (isPosLoggedIn && posId) {
      if (posType == POSType.Restaurants.toString()) {
        targetComponent = <Navigate to={getPath("/restaurants")} />;
      } else {
        targetComponent = <Navigate to={getPath("/pos")} />;
      }
    } else if (isPosLoggedIn)
      targetComponent = <Navigate to={getPath("/home")} />;

    return WrapSuspense(targetComponent);
  }

  return (
    <Routes>
      <Route path="/" element={<UserLoginPage />} />
      <Route path={getPath("/error")} element={<ErrorPage />} />
      <Route
        path="/verify/:id"
        element={AllowGetPage(<EmailValidationPage />)}
      />
      <Route path={"/login"} element={AllowGetPage(<UserLoginPage />)} />
      <Route
        path={"/signup"}
        element={AllowGetPage(<OrganizationSignUpPage />)}
      />
      <Route
        path={"/verify-your-email"}
        element={AllowGetPage(<VerifyYourEmailPage />)}
      />
      <Route
        path={getPath("/login")}
        element={AllowGetPage(<UserLoginPage />)}
      />
      <Route
        path={getPath("/login-pos")}
        element={AllowGetPage(<PosLoginPage />)}
      />
      <Route
        path={getPath("/permissions")}
        element={AllowGetPage(<NoPermissionsPage />)}
      />
      <Route
        path={getPath("/home")}
        element={LoggedInPosHome(<UserHomePage />)}
      />
      <Route path={getPath("/")} element={LoggedIn(<MainPage />)}>
        <Route index element={LoggedIn(<DashboardPage />)} />
        <Route
          path={getPath("/user-profile")}
          element={LoggedIn(
            <UserProfile navigateTo={RouterNavigateType.None} />
          )}
        />
        <Route
          path={getPath("/settings")}
          element={LoggedIn(<SettingsPage />)}
        />
        <Route
          path={getPath("/stores")}
          element={LoggedIn(<StoresListPage />)}
        />
        <Route path={getPath("/users")} element={LoggedIn(<UsersList />)} />
        <Route
          path={getPath("/users-list")}
          element={LoggedIn(<UsersList />)}
        />
        <Route
          path={getPath("/stores-list")}
          element={LoggedIn(<StoresListPage />)}
        />
        <Route
          path={getPath("/stores-list/create-store")}
          element={LoggedIn(<EditStorePage />)}
        />
        <Route
          path={getPath("/stores-list/edit-store/:id")}
          element={LoggedIn(<EditStorePage />)}
        />
        <Route
          path={getPath("/stores-list/edit-client/:id")}
          element={LoggedIn(<EditStorePage />)}
        />
        <Route
          path={getPath("/clients")}
          element={LoggedIn(<ClientsListPage />)}
        />
        <Route
          path={getPath("/clients-list")}
          element={LoggedIn(<ClientsListPage />)}
        />
        <Route
          path={getPath("/clients-list/create-client")}
          element={LoggedIn(
            <EditClientPage navigateTo={RouterNavigateType.None} />
          )}
        />
        <Route
          path={getPath("/clients-list/edit-client/:id")}
          element={LoggedIn(
            <EditClientPage navigateTo={RouterNavigateType.None} />
          )}
        />
        <Route
          path={getPath("/category-list")}
          element={LoggedIn(<CategoryPage />)}
        />
        <Route
          path={getPath("/category-list/:backTo")}
          element={LoggedIn(<CategoryPage />)}
        />
        <Route
          path={getPath("/tag-list")}
          element={LoggedIn(<TagListPage />)}
        />
        <Route
          path={getPath("/tag-list/:backTo")}
          element={LoggedIn(<TagListPage />)}
        />
        <Route path={getPath("/materials")} element={LoggedIn(<ItemsPage />)} />
        <Route
          path={getPath("/materialsAndServicesList")}
          element={LoggedIn(<ItemsPage />)}
        />
        <Route
          path={getPath("/sales-summary")}
          element={LoggedIn(<SalesReports />)}
        />
        <Route
          path={getPath("/customer-statement-report")}
          element={LoggedIn(<CustomerStatementReport />)}
        />
        <Route
          path={getPath("/customer-statement-report/:id")}
          element={LoggedIn(<CustomerStatementReport />)}
        />
        <Route
          path={getPath("/customers-balance-statement-report")}
          element={LoggedIn(<CustomersBalanceStatementReport />)}
        />
        <Route
          path={getPath("/shift-summary")}
          element={LoggedIn(<ShiftReports />)}
        />
        <Route
          path={getPath("/materials/create-material")}
          element={LoggedIn(<EditItemPage />)}
        />
        <Route
          path={getPath("/materials/edit-material/:id")}
          element={LoggedIn(<EditItemPage />)}
        />
        <Route path={getPath("/menus")} element={LoggedIn(<MenusPage />)} />
        <Route path={getPath("/menusList")} element={LoggedIn(<MenusPage />)} />
        <Route
          path={getPath("/menusList/create-menu")}
          element={LoggedIn(<EditMenusPage />)}
        />
        <Route
          path={getPath("/menusList/edit-menu/:id")}
          element={LoggedIn(<EditMenusPage />)}
        />
        <Route
          path={getPath("/comboList")}
          element={LoggedIn(<CombosPage />)}
        />

        <Route
          path={getPath("/comboList/create-combo")}
          element={LoggedIn(<EditCombosPage />)}
        />

        <Route
          path={getPath("/comboList/edit-combo/:id")}
          element={LoggedIn(<EditCombosPage />)}
        />

        <Route
          path={getPath("/preparedMaterials")}
          element={LoggedIn(<PreparedMaterialsPage />)}
        />

        <Route
          path={getPath("/preparedMaterials/create-preparedMaterial")}
          element={LoggedIn(<EditPreparedMaterialsPage />)}
        />
        <Route
          path={getPath("/preparedMaterials/edit-preparedMaterial/:id")}
          element={LoggedIn(<EditPreparedMaterialsPage />)}
        />
        <Route
          path={getPath("/generalModifiersList")}
          element={LoggedIn(<GeneralModifierPage />)}
        />
        <Route
          path={getPath("/generalModifiersList/create-generalModifier")}
          element={LoggedIn(<CreateGeneralModifierPage />)}
        />
        <Route
          path={getPath("/generalModifiersList/edit-generalModifier/:id")}
          element={LoggedIn(<CreateGeneralModifierPage />)}
        />

        <Route
          path={getPath("/salesmen")}
          element={LoggedIn(<SalesmenPage />)}
        />
        <Route
          path={getPath("/salesmen/create-Salesman")}
          element={LoggedIn(<EditISalesmanPage />)}
        />
        <Route
          path={getPath("/salesmen/edit-Salesman/:id")}
          element={LoggedIn(<EditISalesmanPage />)}
        />
        <Route path={getPath("/staff")} element={LoggedIn(<EmployeesPage />)} />
        <Route
          path={getPath("/cashiersAndSupervisors")}
          element={LoggedIn(<EmployeesPage />)}
        />
        <Route
          path={getPath("/create-employee")}
          element={LoggedIn(<EditEmployeePage />)}
        />
        <Route
          path={getPath("/edit-employee/:id")}
          element={LoggedIn(<EditEmployeePage />)}
        />
        <Route
          path={getPath("/users/create")}
          element={LoggedIn(<UserPage />)}
        />
        <Route path={getPath("/users/:id")} element={LoggedIn(<UserPage />)} />
        <Route
          path={getPath("/pos-list")}
          element={LoggedIn(<PosListPage />)}
        />
        <Route
          path={getPath("/edit-pos")}
          element={LoggedIn(<EditPosPage />)}
        />
        <Route
          path={getPath("/create-pos")}
          element={LoggedIn(<EditPosPage />)}
        />
        <Route
          path={getPath("/edit-pos/:id")}
          element={LoggedIn(<EditPosPage />)}
        ></Route>
        <Route path={getPath("/Driver")} element={LoggedIn(<DriversPage />)} />
        <Route
          path={getPath("/Driver/create-driver")}
          element={LoggedIn(<DriverPage />)}
        />
        <Route
          path={getPath("/Driver/edit-driver/:id")}
          element={LoggedIn(<DriverPage />)}
        />
        <Route
          path={getPath("/Driver/create-driver")}
          element={LoggedIn(<DriverPage />)}
        />
        <Route
          path={getPath("/Driver/edit-driver/:id")}
          element={LoggedIn(<DriverPage />)}
        />
        <Route
          path={getPath("/printDesign-list")}
          element={LoggedIn(<PrintingDesignListPage />)}
        />
        <Route
          path={getPath("/printDesign-list/create-print-design")}
          element={LoggedIn(<EditPrintDesignPage />)}
        />
        <Route
          path={getPath("/printDesign-list/edit-print-design/:id")}
          element={LoggedIn(<EditPrintDesignPage />)}
        />
        <Route
          path={getPath("/shiftSummary/:id")}
          element={LoggedIn(<ShiftSummaryBackOfficePage />)}
        />
        <Route
          path={getPath("/calculatedCashDetails/:id")}
          element={LoggedIn(<CalculatedCashDetailsBackOfficePage />)}
        />
        <Route
          path={getPath("/materialsTransactionDetails/:id")}
          element={LoggedIn(<MaterialsTransactionDetailsBackOfficePage />)}
        />
        <Route
          path={getPath("/externalTransactionDetails/:id")}
          element={LoggedIn(<ExternalTransactionDetailsBackOfficePage />)}
        />
        <Route
          path={getPath("/shiftClosingDetails/:id")}
          element={LoggedIn(<ShiftClosingDetailsBackOfficePage />)}
        />
        <Route path="*" element={<div>invalid route</div>} />
      </Route>

      <Route path={getPath("/pos")} element={LoggedInPos(<PosMainPage />)}>
        <Route index element={LoggedInPos(<MaterialsTransactions />)} />
        <Route
          path={getPath("/pos/materialsTransactions")}
          element={LoggedInPos(<MaterialsTransactions />)}
        />

        <Route
          path={getPath("/pos/materialsTransactions/finished")}
          element={LoggedInPos(<FinishedTransaction />)}
        />
        <Route
          path={getPath("/pos/materialsTransactions/finished/:id")}
          element={LoggedInPos(<TransactionDetails />)}
        />
        <Route
          path={getPath("/pos/shiftSummary/:id")}
          element={LoggedInPos(<ShiftSummaryPOSPage />)}
        />
        <Route
          path={getPath("/pos/calculatedCashDetails/:id")}
          element={LoggedInPos(<CalculatedCashDetailsPOSPage />)}
        />
        <Route
          path={getPath("/pos/materialsTransactionDetails/:id")}
          element={LoggedInPos(<MaterialsTransactionDetailsPOSPage />)}
        />
        <Route
          path={getPath("/pos/externalTransactionDetails/:id")}
          element={LoggedInPos(<ExternalTransactionDetailsPOSPage />)}
        />
        <Route
          path={getPath("/pos/shiftClosingDetails/:id")}
          element={LoggedInPos(<ShiftClosingDetailsPOSPage />)}
        />
        <Route
          path={getPath("/pos/customer-statement-report/:id")}
          element={LoggedInPos(<CustomerStatementPosReport />)}
        />
        <Route
          path={getPath("/pos/specialOffers")}
          element={LoggedInPos(<>special Offers</>)}
        />
        <Route
          path={getPath("/pos/shiftsAndExternals")}
          element={LoggedInPos(<ShiftsAndExternalsPage />)}
        />
        <Route
          path={getPath("/pos/OrderManagement")}
          element={LoggedInPos(<OrderManagementPage />)}
        />
        <Route
          path={getPath("/pos/settings")}
          element={LoggedInPos(<>settings</>)}
        />
        <Route
          path={getPath("/pos/materials")}
          element={LoggedInPos(<TicketSection pathname={""} />)}
        />
        <Route
          path={getPath("/pos/posDrawer")}
          element={LoggedInPos(<ConfigurationPage />)}
        />

        <Route
          path={getPath("/pos/clientsList/:id")}
          element={LoggedInPos(<PosClientsListPage />)}
        />
        <Route
          path={getPath("/pos/create-client")}
          element={LoggedInPos(
            <EditClientPage
              navigateTo={RouterNavigateType.Pos}
              permissions={getClientPagePermissions()}
            />
          )}
        />
        <Route
          path={getPath("/pos/edit-client/:id")}
          element={LoggedInPos(
            <EditClientPage
              navigateTo={RouterNavigateType.Pos}
              permissions={getClientPagePermissions()}
            />
          )}
        />

        <Route
          path={getPath("/pos/statistics-client/:id")}
          element={LoggedInPos(<StatisticsPage />)}
        />

        <Route
          path={getPath("/pos/delivery-client/:id")}
          element={LoggedInPos(<DeliveryOrdersPage />)}
        />

        <Route
          path={getPath("/pos/pickup-client/:id")}
          element={LoggedInPos(<PickupOrdersPage />)}
        />

        <Route
          path={getPath("/pos/ticket-payment")}
          element={LoggedInPos(<TicketPayment />)}
        />

        <Route
          path={getPath("/pos/user-profile")}
          element={LoggedInPos(
            <UserProfile navigateTo={RouterNavigateType.Pos} />
          )}
        />

        <Route path="*" element={<div>invalid route</div>} />
      </Route>

      <Route
        path={getPath("/restaurants")}
        element={LoggedInRest(<RestaurantsMainPage />)}
      >
        <Route
          path={getPath("/restaurants/statistics-client/:id")}
          element={LoggedInRest(<StatisticsPage />)}
        />

        <Route
          path={getPath("/restaurants/delivery-client/:id")}
          element={LoggedInRest(<DeliveryOrdersPage />)}
        />

        <Route
          path={getPath("/restaurants/pickup-client/:id")}
          element={LoggedInRest(<PickupOrdersPage />)}
        />

        <Route index element={LoggedInRest(<RestMaterialsTransactions />)} />
        <Route
          path={getPath("/restaurants/shiftsAndExternals")}
          element={LoggedInRest(<ShiftsAndExternalsPage />)}
        />
        <Route
          path={getPath("/restaurants/OrderManagement")}
          element={LoggedInRest(<OrderManagementPage />)}
        />
        <Route
          path={getPath("/restaurants/shiftSummary/:id")}
          element={LoggedInRest(<ShiftSummaryRestPage />)}
        />
        <Route
          path={getPath("/restaurants/calculatedCashDetails/:id")}
          element={LoggedInRest(<CalculatedCashDetailsRestPage />)}
        />
        <Route
          path={getPath("/restaurants/materialsTransactionDetails/:id")}
          element={LoggedInRest(<MaterialsTransactionDetailsRestPage />)}
        />
        <Route
          path={getPath("/restaurants/externalTransactionDetails/:id")}
          element={LoggedInRest(<ExternalTransactionDetailsRestPage />)}
        />
        <Route
          path={getPath("/restaurants/shiftClosingDetails/:id")}
          element={LoggedInRest(<ShiftClosingDetailsRestPage />)}
        />
        <Route
          path={getPath("/restaurants/materialsTransactions")}
          element={LoggedInRest(<RestMaterialsTransactions />)}
        />
        <Route
          path={getPath("/restaurants/materialsTransactions/finished")}
          element={LoggedInRest(<FinishedTransaction />)}
        />
        <Route
          path={getPath("/restaurants/materialsTransactions/finished/:id")}
          element={LoggedInRest(<TransactionDetails />)}
        />
        <Route
          path={getPath("/restaurants/customer-statement-report/:id")}
          element={LoggedInRest(<CustomerStatementRestaurantReport />)}
        />
        <Route
          path={getPath("/restaurants/user-profile")}
          element={LoggedInRest(
            <UserProfile navigateTo={RouterNavigateType.Restaurant} />
          )}
        />

        <Route
          path={getPath("/restaurants/create-client")}
          element={LoggedInRest(
            <EditClientPage
              navigateTo={RouterNavigateType.Restaurant}
              permissions={getClientPagePermissions()}
            />
          )}
        />
        <Route
          path={getPath("/restaurants/edit-client/:id")}
          element={LoggedInRest(
            <EditClientPage
              navigateTo={RouterNavigateType.Restaurant}
              permissions={getClientPagePermissions()}
            />
          )}
        />
        <Route path="*" element={<div>invalid route</div>} />
      </Route>

      <Route path="*" element={<div>invalid route</div>} />
    </Routes>
  );
}
