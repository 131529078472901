export enum TransactionTypes {
  sales,
  salesReturns,
  deliveryOrder,
  pickUpOrder,
}
export enum TicketStatus {
  new = -1,
  paid = 0,
  opened = 1,
  canceled = 2,
  refund = 3,
  deleted = 4,
  waiting = 5,
  assigned = 6,
  inDelivery = 7,
}

export enum OrderType {
  none = 0,
  pickup = 1,
  delivery = 2,
  fastService = 3,
  fullService = 4,
}
