import { IExternalOperation } from "amnPos/shiftsAndExternals/externalOperations/ExternalOperationData";
import {
  EmptyDeliveryOrderInfo,
  IDeliveryOrderInfo,
} from "amnShared/interfaces/IDeliveryOrderInfo";
import {
  EmptyPickUpOrderInfo,
  IPickUpOrderInfo,
} from "amnShared/interfaces/IPickUpOrderInfo";
import { store } from "app/store";
import {
  TransactionTypes,
  TicketStatus,
  OrderType,
} from "models/common-props/TransactionTypes";
import { TicketPaymentGetway } from "models/PaymentModel";
import { NIL } from "uuid";

export interface IItemDetail {
  item: string;
  quantity: number;
  unit: string;
  price: number;
  showQuantity: () => boolean;
  showPrice: () => boolean;
  subItem: IItemDetail[];
}

export default interface Ticket {
  id: string;
  number: number;
  code: string;
  note: string;
  total: number;
  totalPaid: number;
  taxTotal: number;
  type: TransactionTypes;
  orderType: OrderType;
  isDiscountPercentage: boolean;
  discountValue: number;
  status: TicketStatus;
  additionTime: Date | string;

  shiftCode: string;
  posId: string;
  employeeId: string;
  employee: string;
  salesmanId: string | null;
  salesman: string | null;
  clientId: string | null;
  client: string | null;
  ticketPaymentGetways: TicketPaymentGetway[];
  ticketItems: TicketItem[];
  deliveryOrderInfo?: IDeliveryOrderInfo | null;
  pickupOrderInfo?: IPickUpOrderInfo | null;
  externalOperations?: IExternalOperation[];
}

export enum ItemBehavior {
  Inventory,
  Service,
}

export interface TicketItem {
  id: string;
  number: number;
  itemType: number;
  itemBehavior: ItemBehavior;
  ticketId: string;
  menuItemId: string;
  itemId: string;
  preparedMaterialId: string;
  comboId: string;
  item: string | null;
  image?: string;
  price: number;
  qty: number;
  discountPercent: number;
  discountValue: number;
  giftQty: number;
  value: number;
  total: number;
  net: number;
  taxValue: number;
  unit: string | null;
  unitId: string;
  salesmanId: string | null;
  salesman: string | null;
  isReseted: boolean;
  barcode?: string;
  giftsValue: number;
  giftsPercent: number;
  menuId: string;
  modifierTicketItems: ModifierTicketItem[];
  description?: string;
}

export enum TicketModifierItemType {
  PreparedFixedIngredient,
  PrepIncreaseDecrease,
  PrepAlternative,
  PrepMultipleAlternative,
  ComboFixedItems,
  ComboAlternatives,
  ComboGeneralModifiers,
}

export interface ModifierTicketItem {
  id: string;
  modifierRowItemId: string;
  number: number;
  ticketItemId: string;
  modifierId: string;
  modifierName: string;
  itemId: string;
  item: string | null;
  image?: string;
  price: number;
  extraPrice: number;
  maximumMultiple: number;
  maximumDefault: number;
  idontwant: boolean;
  idontwantDefault: boolean;
  quantity: number;
  stepQuantity: number;
  startsAt: number;
  value: number;
  totalValue: number;
  unit: string | null;
  unitId: string;
  modifierType: TicketModifierItemType;
  subModifierTicketItemDtos: ModifierTicketItem[];
  isDefault: boolean;
  isChangeQuantity: boolean;
  note: string;
}

export const EmptyModifierTicketItem: ModifierTicketItem = {
  id: NIL,
  modifierRowItemId: NIL,
  number: 0,
  ticketItemId: NIL,
  modifierId: NIL,
  modifierName: "",
  itemId: NIL,
  item: "",
  image: "",
  price: 0,
  quantity: 0,
  stepQuantity: 0,
  startsAt: 0,
  maximumMultiple: 0,
  maximumDefault: 0,
  extraPrice: 0,
  value: 0,
  totalValue: 0,
  idontwant: false,
  idontwantDefault: false,
  unit: "",
  unitId: NIL,
  modifierType: TicketModifierItemType.PrepIncreaseDecrease,
  subModifierTicketItemDtos: [],
  isDefault: false,
  isChangeQuantity: false,
  note: "",
};

export interface SubModifierTicketItem {
  id: string;
  number: number;
  modifierTicketItemId: string;
  itemId: string;
  item: string | null;
  image?: string;
  price: number;
  qty: number;
  value: number;
  total: number;
  unit: string | null;
  unitId: string;
}

export const emptyTicketItem: TicketItem = {
  id: NIL,
  number: 0,
  itemBehavior: ItemBehavior.Inventory,
  itemType: 0,
  menuItemId: NIL,
  ticketId: NIL,
  itemId: NIL,
  preparedMaterialId: NIL,
  comboId: NIL,
  item: "",
  image: "",
  price: 0,
  qty: 0,
  discountPercent: 0,
  discountValue: 0,
  giftQty: 0,
  value: 0,
  total: 0,
  net: 0,
  taxValue: 0,
  unit: "",
  unitId: NIL,
  salesmanId: NIL,
  salesman: "",
  isReseted: false,
  barcode: "",
  giftsValue: 0,
  giftsPercent: 0,
  menuId: NIL,
  modifierTicketItems: [],
  description: "",
};

export interface ItemDiscount {
  discPercent?: number;
  discValue?: number;
}

export const ticketDto = (ticket: Ticket) => {
  return {
    ...ticket,
    salesman: null,
    salesmanId: null,
    client: null,
    employeeId: store.getState().employeeSettings.id,
    shiftId: store.getState().shiftInfo.id,
    shift: null,
    ticketItems: ticket.ticketItems.map((item, index) => {
      return {
        ...item,
        number: index,
        ticketId: ticket.id,
        salesman: null,
        salesmanId: null,
        unit: null,
        item: null,
      };
    }),
  } as Ticket;
};

export interface ITicketItemsTotals {
  subTotal: number;
  itemDiscount: number;
  vat: number;
  total: number;
}

export const emptyTicketItemsTotals: ITicketItemsTotals = {
  subTotal: 0,
  itemDiscount: 0,
  vat: 0,
  total: 0,
};

export interface ITicketPaymentsTotals {
  transactionValue: number;
  collected: number;
  deferredAmount: number;
}

export const emptyTicketPaymentsTotals: ITicketPaymentsTotals = {
  transactionValue: 0,
  collected: 0,
  deferredAmount: 0,
};

export const emptyTicket: Ticket = {
  id: NIL,
  number: 0,
  code: "",
  note: "",
  total: 0,
  totalPaid: 0,
  taxTotal: 0,
  type: TransactionTypes.sales,
  orderType: OrderType.fastService,
  isDiscountPercentage: false,
  discountValue: 0,
  status: TicketStatus.new,
  additionTime: "",
  shiftCode: "",
  posId: "",
  employeeId: NIL,
  employee: "",
  salesmanId: null,
  salesman: null,
  clientId: null,
  client: null,
  ticketPaymentGetways: [],
  ticketItems: [],
  deliveryOrderInfo: null,
  pickupOrderInfo: null,
};

export type CustomizedTicketDocuments = { [key: string]: string };

export function mapToItemDetail(
  modifierTicketItem: ModifierTicketItem
): IItemDetail {
  const {
    item,
    quantity,
    unit,
    extraPrice,
    totalValue,
    modifierType,
    isChangeQuantity,
    startsAt,
    subModifierTicketItemDtos,
  } = modifierTicketItem;

  return {
    item: item || "",
    quantity: quantity,
    unit: unit || "",
    price: totalValue,
    subItem: subModifierTicketItemDtos
      ? subModifierTicketItemDtos.map(mapToItemDetail)
      : [],
    showQuantity: () => {
      switch (modifierType) {
        case TicketModifierItemType.PrepIncreaseDecrease:
          return true;
        case TicketModifierItemType.PrepAlternative:
          return false;
        case TicketModifierItemType.PrepMultipleAlternative:
          return isChangeQuantity;
        case TicketModifierItemType.ComboFixedItems:
          return true;
        case TicketModifierItemType.ComboAlternatives:
          return false;
        case TicketModifierItemType.ComboGeneralModifiers:
          return false;
        default:
          return false;
      }
    },
    showPrice: () => {
      switch (modifierType) {
        case TicketModifierItemType.PrepIncreaseDecrease:
          return extraPrice.isGreaterThan(0);
        case TicketModifierItemType.PrepAlternative:
          return totalValue.isGreaterThan(0);
        case TicketModifierItemType.PrepMultipleAlternative:
          return (
            extraPrice.isGreaterThan(0) &&
            quantity.isGreaterThanOrEqualTo(startsAt)
          );
        case TicketModifierItemType.ComboFixedItems:
          totalValue.isGreaterThan(0);
        case TicketModifierItemType.ComboAlternatives:
          return totalValue.isGreaterThan(0);
        case TicketModifierItemType.ComboGeneralModifiers:
          return extraPrice.isGreaterThan(0);
        default:
          return false;
      }
    },
  };
}
